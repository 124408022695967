<template>
  <div>
    <div class="flex items-center justify-between border-b pb-4">
      <h3 class="font-bold">{{ project.projectsTotal }} Proyectos</h3>
      <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
        Agregar proyecto
      </BaseButton>
    </div>

    <div class="mt-5">
      <div class="flex flex-wrap -m-4">
        <ProjectCard v-for="project in project.projects" :project="project" :key="project.id" />
      </div>
    </div>

    <ProjectCreateModal
      :tenant="tenant.current"
      :show="showCreateModal"
      @close="showCreateModal = false"
    />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';
import ProjectCard from '@/components/ProjectCard';
import ProjectCreateModal from './Projects/components/ProjectCreateModal';

export default {
  components: { BaseButton, ProjectCard, ProjectCreateModal },
  name: 'ProjectList',
  data() {
    return {
      showCreateModal: false,
    };
  },
  computed: {
    ...mapState(['project', 'tenant']),
  },

  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  await store.dispatch('project/fetchProjects', to.params.id);
  next();
}
</script>
