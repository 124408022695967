<template>
  <BaseModal :show="show" @close="$emit('close')">
    <h3 slot="header" class="font-bold text-base">Agregar proyecto</h3>

    <div class="space-y-6">
      <ImagePreviewInput v-model="projectLogo" class="flex flex-col items-center w-48 mx-auto">
        <div slot="caption" class="mt-2 text-center">
          <p>Agrega un logo</p>
          <p class="text-xs mt-1">(La imágen de pesar menos de 300kb en formato .png o .jpg)</p>
        </div>
      </ImagePreviewInput>
      <div v-if="errors.has('company_logo')" class="text-sm text-red-600 pl-10 mt-1">
        {{ errors.first('company_logo') }}
      </div>

      <div>
        <BaseInput
          label="Nombre del proyecto"
          placeholder="Nombre del proyecto"
          :error="errors.first('name')"
          v-model="name"
        />
      </div>

      <div>
        <BaseInput
          label="Dominio"
          placeholder="Dominio"
          :error="errors.first('domain')"
          v-model="domain"
        />
      </div>

      <!-- Project theme color -->
      <div>
        <div class="text-xs">Color principal</div>
        <BaseColorPicker v-model="theme_color" class="mt-2" />
        <div v-if="errors.has('theme_color')" class="text-sm text-red-600 pl-10 mt-1">
          {{ errors.first('theme_color') }}
        </div>
      </div>

      <div>
        <TimezoneInput
          label="Zona horaria"
          placeholder="Zona horaria"
          :error="errors.first('timezone')"
          v-model="timezone"
        />
      </div>

      <div>
        <div class="text-xs ml-3">Espacio en la cuenta (Gb)</div>

        <div class="flex flex-col items-stretch mt-4">
          <input
            class="flex-grow"
            type="range"
            v-model.number="spaceLimit"
            list="tickmarks"
            :min="min"
            :max="max"
            :step="step"
          />
          <datalist id="tickmarks" class="flex justify-between">
            <option v-for="item in range" :key="item" :value="item" :label="`${item}`"></option>
          </datalist>
        </div>
      </div>
    </div>

    <div slot="footer" class="mt-6 flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="$emit('close')">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleAddProject"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg
        >Agregar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';
import BaseButton from '@/components/BaseButton';
import TimezoneInput from '@/components/TimezoneInput';
import BaseColorPicker from '@/components/BaseColorPicker';
import ImagePreviewInput from '@/components/ImagePreviewInput';

export default {
  name: 'ProjectCreateModal',
  components: {
    BaseModal,
    BaseInput,
    BaseButton,
    TimezoneInput,
    BaseColorPicker,
    ImagePreviewInput,
  },
  props: {
    show: { type: Boolean, default: false },
    tenant: { type: Object, required: true },
  },
  data() {
    return {
      projectLogo: null,
      domain: '',
      errors: new Errors(),
      latitude: 19.3996876,
      loading: false,
      longitude: -99.1589619,
      max: 100,
      min: 5,
      name: '',
      spaceLimit: 10,
      step: 5,
      timezone: null,
      theme_color: '',
    };
  },
  computed: {
    range() {
      let items = [];
      for (let index = this.min; index <= this.max; index += this.step) {
        items.push(index);
      }

      return items;
    },
  },
  methods: {
    async handleAddProject() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('space_limit', this.spaceLimit);
      formData.append('latitude', this.latitude);
      formData.append('longitude', this.longitude);
      formData.append('domain', this.domain);
      formData.append('theme_color', this.theme_color);
      formData.append('timezone', this.timezone);

      if (this.projectLogo) {
        formData.append('logo', this.projectLogo);
      }

      this.loading = true;
      this.errors.clear();

      try {
        const project = await this.$store.dispatch('project/createProject', {
          tenantId: this.tenant.id,
          data: formData,
        });
        this.clearForm();
        this.$emit('close');
        this.$emit('created', project);
        this.$store.dispatch('notification/addSuccess', 'El proyecto se ha creado correctamente');
        this.$router.push({ name: 'project-location', params: { project_id: project.id } });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.$store.dispatch('notification/addError', error.response.data.errors);
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
    clearForm() {
      this.name = '';
      this.spaceLimit = '';
      this.projectLogo = '';
      this.domain = '';
      this.latitude = '';
      this.longitude = '';
      this.timezone = null;
    },
  },
};
</script>
