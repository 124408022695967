<template>
  <div @click.stop="setCurrentProject(project)" class="m-4 cursor-pointer">
    <div class="bg-white shadow-sm py-3 px-4 flex items-center h-20 w-72 relative group">
      <img
        class="h-16 w-16"
        :src="project.logo_url ? project.logo_url : `${publicPath}img/logo-placeholder.png`"
        alt="empresa"
      />
      <div class="ml-4 w-40">
        <h3 class="font-bold">{{ project.name }}</h3>
        <p class="text-xs">{{ project.cameras_count }} cámaras</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    project: { type: Object, required: true },
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    setCurrentProject(project) {
      this.$store.commit('project/SET_CURRENT_PROJECT', project);
      this.$router.push({ name: 'project-home', params: { project_id: project.id } });
    },
  },
};
</script>
